import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EcPetMode = () => {
  return (
    <section className="pet-mode">
        <Container>
            <Row className="align-items-center">
                <Col lg={6}>
                    <h2 className="sub-title text-white">Pet mode</h2>
                    <p className="cs-sub-text text-white pb-5">This feature comes in handy if the vehicle is owned by multiple owners. The user can pre-condition the vehicle through the app to suit their routine and preferences.</p>
                    <div className="petm-left-img text-lg-left text-center">
                        <StaticImage src="../../../images/case-studies/electric-car-commute-app/pet-mode-left.png" alt="Electric Car Commute App UX/UI Case Studies Pet Mode features | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={392} height={302}/>
                    </div>
                </Col>
                <Col lg={6} className="text-center mt-5 mt-lg-0 petm-right-img">
                    <StaticImage src="../../../images/case-studies/electric-car-commute-app/petmode-right.png" alt="Electric Car Commute App UX/UI Case Studies Pet Mode features | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={487} height={988}/>
                </Col>				
            </Row>
        </Container>
    </section>  
  )
}

export default EcPetMode
