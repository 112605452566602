import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EcTribe = () => {
  return (
    <section className="rivian-tribe-img pb-0">        
        <Container fluid>
            <Row className="justify-content-center">
                <Col lg={7} className="text-center">
                    <h2 className="sub-title text-white">Community for Rivian customers</h2>
                    <p className="text-white cs-sub-text">We materialized the Rivian tribe feature when we realized the advantage of having a community where Rivian users could post updates about their cars, have fruitful conversations, read articles, ask questions and seek answers.</p>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/electric-car-commute-app/rivian-tribe.jpg" alt="Electric Car Commute App UX/UI Case Studies Rivian Tribe | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1440} height={976}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default EcTribe