import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EcPreconditions = () => {
  return (
    <section className="preconditions pb-0"> 
        <Container>
            <Row>
                <Col lg={8}>
                    <h2 className="sub-title text-white">Roadside assistance and periodic maintenance</h2>
                    <p className="cs-sub-text text-white">Sometimes, vehicles have a mind of their own. They could break down at the most unexpected hour which is why we added the Roadside Assistance feature to give users access to repair services in emergency situations.</p>
                </Col>
            </Row>
        </Container>       
        <Container fluid>
            <Row className="justify-content-center">
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/electric-car-commute-app/preconditions.png" alt="Electric Car Commute App UX/UI Case Studies Rivian Roadside assistance and Preconditions | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1440} height={2313}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default EcPreconditions