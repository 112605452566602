import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import EcMain from "../../components/case-studies/electric-car-commute-app/ecMain"
import EcInfo from "../../components/case-studies/electric-car-commute-app/ecInfo"
import EcFullwidthTop from "../../components/case-studies/electric-car-commute-app/ecFullwidthTop"
import EcFullwidthSecond from "../../components/case-studies/electric-car-commute-app/ecFullwidthSecond"
import EcTribe from "../../components/case-studies/electric-car-commute-app/ecTribe"
import EcTripPlanning from "../../components/case-studies/electric-car-commute-app/ecTripPlanning"
import EcRoadAssistance from "../../components/case-studies/electric-car-commute-app/ecRoadAssistance"
import EcPreconditions from "../../components/case-studies/electric-car-commute-app/ecPreconditions"
import EcPetMode from "../../components/case-studies/electric-car-commute-app/ecPetMode"
import EcFullwidthBottom from "../../components/case-studies/electric-car-commute-app/ecFullwidthBottom"

import EcMoreCs from "../../components/case-studies/electric-car-commute-app/ecMoreCs"
import DirectContact from "../../components/global/directContact"

import csEccpFeaturedImage from "../../../static/case-studies/electric-car-commute-app/rivian-electric-car-commute-app-featured-img.jpg"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/electric-car-commute-app.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csEccpFeaturedImage}
      title="Electric Vehicle (EV) Commute and Charging App Case Study | UX/UI Design | Automotive"
      description="We designed a sleek EV app with intuitive UX/UI design that helped customers easily plan, track, and charge their electric vehicles for a seamless and sustainable commuting experience"
    />
  </> 
)

const ElectricCarCommuteApp = () => {
    return (
      <Layout>
        <Seo
          bid="cs-electric-app"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <EcMain />
        <EcInfo />
        <EcFullwidthTop />
        <EcFullwidthSecond />
        <EcTribe />
        <EcTripPlanning />
        <EcRoadAssistance />
        <EcPreconditions />
        <EcPetMode />
        <EcFullwidthBottom />
        <EcMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default ElectricCarCommuteApp
