import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EcTripPlanning = () => {
  return (
    <section className="trip-planing pb-0">        
        <Container fluid>
            <Row className="justify-content-center">
                <Col lg={7} className="text-center">
                    <h2 className="sub-title">Trip planning</h2>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/electric-car-commute-app/rivian-full-width-3.png" alt="Electric Car Commute App UX/UI Case Studies Rivian Trip planning | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1456} height={1168}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default EcTripPlanning