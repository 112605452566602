import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EcFullwidthBottom = () => {
  return (
    <section className="rivian-full-width py-0">        
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/electric-car-commute-app/full-screen-bottom.jpg" alt="Electric Car Commute App UX/UI Case studies | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1440} height={854}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default EcFullwidthBottom