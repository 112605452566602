import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const EcRoadAssistance = () => {
  return (
    <section className="road-assistance py-0">        
        <Container fluid>
            <Row className="justify-content-center">
                <Col lg={7} className="text-center">
                    <h2 className="sub-title">Roadside assistance and periodic maintenance</h2>
                    <p className="cs-sub-text">Sometimes, vehicles have a mind of their own. They could break down at the most unexpected hour which is why we added the Roadside Assistance feature to give users access to repair services in emergency situations.</p>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/case-studies/electric-car-commute-app/road-side-assistance.png" alt="Electric Car Commute App UX/UI Case Studies Rivian Roadside assistance and periodic maintenance | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1440} height={2359}/>
                </Col>                
            </Row>
        </Container>
    </section>
  )
}

export default EcRoadAssistance