import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const EcInfo = () => {
  return (
    <section className="cs-info position-relative">
		
        <Container className="position-relative">
            <Row className="pb-md-5 pb-0">
                <Col lg={6}>
                    <h1 className="u-section text-white mb-4">Vehicles that care about people and the planet</h1>
                    <p className="cs-sub-text text-white mb-4">We designed the Rivian concept app to help vehicles understand the users to deliver a seamless driving experience. The app also strives to make the vehicles environmentally friendly, to preserve nature for a better tomorrow. </p>
                    <div className="col-md-8 px-0 details-mini d-flex justify-content-between">
                        <div className="stats-info">
                            <p className="cs-sub-text text-white st-title mb-0">Duration</p>
                            <p className="st-data text-white mb-0">3 Months</p>
                        </div>
                        <div className="stats-info">
                            <p className="cs-sub-text text-white st-title mb-0">Services</p>
                            <p className="st-data text-white mb-0">UX/UI</p>
                        </div>
                        <div className="stats-info">
                            <p className="cs-sub-text st-title text-white mb-0">Platform</p>
                            <p className="st-data text-white mb-0">iOS, Android</p>
                        </div>												
                    </div>
                </Col>
                <Col lg={3} className="offset-lg-3 text-lg-end mt-lg-0 mt-md-5 mt-5">
                    <div className="position-relative">
                    <svg width="176" height="160" viewBox="0 0 176 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M175.656 73.8021C165.701 64.1753 111.846 12.101 101.751 4.58806C98.2289 1.94173 93.6931 0 88.0035 0C82.305 0 77.7692 1.94173 74.2558 4.58806C64.1609 12.101 10.2973 64.1753 0.34265 73.8021C-0.470158 74.6788 0.263991 76.055 1.48757 76.055C6.46056 76.055 17.9884 76.0714 24.9366 76.0714C27.3838 76.0714 28.1092 75.6701 29.6474 74.1216C31.1856 72.5731 38.8417 64.8716 38.8417 64.8716C48.5778 55.032 72.3242 33.3043 81.0029 26.455C84.2627 23.8824 87.9509 23.9644 88.0035 23.9644C88.0557 23.9644 91.744 23.8824 95.0042 26.455C103.683 33.3125 127.429 55.04 137.165 64.8716C137.165 64.8716 144.821 72.5731 146.359 74.1216C147.898 75.6701 148.623 76.0714 151.07 76.0714C158.01 76.0714 169.546 76.055 174.519 76.055C175.743 76.0634 176.468 74.6788 175.656 73.8021Z" fill="white"/>
                        <path d="M0.343085 86.198C10.2978 95.8248 64.1527 147.899 74.2472 155.412C77.7696 158.058 82.3054 160 87.9949 160C93.6935 160 98.2293 158.058 101.743 155.412C111.838 147.899 165.692 95.8248 175.647 86.198C176.46 85.3213 175.726 83.9451 174.502 83.9451C169.529 83.9451 158.001 83.9287 151.053 83.9287C148.606 83.9287 147.881 84.33 146.342 85.8785C144.804 87.4271 137.148 95.1285 137.148 95.1285C127.412 104.968 103.666 126.696 94.987 133.545C91.7273 136.118 88.039 136.036 87.9864 136.036C87.9338 136.036 84.2455 136.118 80.9857 133.545C72.3071 126.687 48.5611 104.96 38.8247 95.1285C38.8247 95.1285 31.1686 87.4271 29.6303 85.8785C28.0921 84.33 27.3667 83.9287 24.9196 83.9287C17.9801 83.9287 6.44349 83.9451 1.47052 83.9451C0.264426 83.9451 -0.469721 85.3213 0.343085 86.198Z" fill="white"/>
                        <path d="M81.4887 97.2173C77.0491 93.4158 69.227 86.3041 66.3165 83.1172C64.9879 81.7082 65.0751 79.9464 65.0751 79.9464C65.0751 79.9464 64.9879 78.185 66.3165 76.7759C69.227 73.5886 77.0491 66.4773 81.4887 62.6759C83.3065 61.0865 83.7435 60.0867 83.7435 58.1369C83.7435 52.1071 83.7435 40.8007 83.7435 36.5158C83.7435 35.6474 82.6599 35.1066 81.8209 35.8358C71.9189 44.4301 49.8594 64.9207 42.4653 72.8595C38.9257 76.3743 39.2578 79.9548 39.2578 79.9548C39.2578 79.9548 38.9344 83.5269 42.4653 87.0497C49.8679 94.9888 71.9274 115.479 81.8209 124.074C82.7036 124.795 83.7435 124.344 83.7435 123.32C83.7435 119.027 83.7435 107.794 83.7435 101.764C83.7435 99.798 83.3065 98.7987 81.4887 97.2173Z" fill="white"/>
                        <path d="M94.5113 97.2339C98.9514 93.4325 106.773 86.3212 109.684 83.1339C111.012 81.7249 110.925 79.9635 110.925 79.9635C110.925 79.9635 111.012 78.2017 109.684 76.7926C106.773 73.6057 98.9514 66.494 94.5113 62.6926C92.6935 61.1031 92.2565 60.1038 92.2565 58.1536C92.2565 52.1238 92.2565 40.8911 92.2565 36.5981C92.2565 35.574 93.2965 35.1233 94.1795 35.8443C104.082 44.4388 126.141 64.9294 133.535 72.8681C137.066 76.3829 136.742 79.9635 136.742 79.9635C136.742 79.9635 137.066 83.5356 133.535 87.0584C126.132 94.9975 104.073 115.488 94.1795 124.082C93.2965 124.803 92.2565 124.352 92.2565 123.329C92.2565 119.035 92.2565 107.803 92.2565 101.773C92.2654 99.8147 92.6935 98.8234 94.5113 97.2339Z" fill="white"/>
                        </svg>
                    </div>

                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default EcInfo
